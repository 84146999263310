<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {dateUtil} from "@/helpers/date-util";
import axios from "axios";
import {useUserStore} from "@/store/user";
import {computed} from "vue";
import {paginationHelper} from "@/helpers/pagination-helper";
import {clipboardHelper} from "@/helpers/clipboard-helper";


/**
 * Account List Component
 */
export default {

  components: {

    Layout,
    PageHeader
  },

  data() {
    return {
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        items: []
      },

      inputPage: "",
    };
  },

  methods: {

    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('affiliate-program.account-list.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        {key: "firstName", label: this.$t('message.first-name')},
        {key: "lastName", label: this.$t('message.last-name')},
        {key: "createdAt", label: this.$t('affiliate-program.account-list.table.created-at'), formatter: value => {return dateUtil.asDateTime(value)}}
      ]
    },

    async loadAccounts() {
      const user = useUserStore().getUser

      let page = this.table.currentPage - 1;
      if (page > 0) {
        page = this.table.currentPage * this.table.perPage - this.table.perPage;
      }

      const result = await axios.get(`/user/affiliate-users`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "referralUserId": user.id,
          "page": page,
          "size": this.table.perPage
        }
      });

      this.table.items = result.data
      await this.loadAccountsCount()

      return this.table.items;
    },

    async loadAccountsCount() {
      const user = useUserStore().getUser

      const result = await axios.get(`/user/affiliate-users/count`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "referralUserId": user.id
        }
      });

      this.table.totalRows = result.data;
      this.table.rows = result.data;
    }
  },

  computed: {
    clipboardHelper() {
      return clipboardHelper
    },
    paginationHelper() {
      return paginationHelper
    },

    getBaseUrl() {
      return process.env.VUE_APP_BASE_URL;
    }

  },

  async created() {
    try {
      // await userService.login(false)
      await this.loadAccounts()
    } catch (error) {
      console.log(error)
    }
  },

  setup() {
    const userStore = useUserStore();
    return {
      user: computed(() => userStore.getUser)
    }
  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('affiliate-program.account-list.title')" :items="getItems()" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" v-if="user">
          <div class="card-body">
            <div v-html="$t('referral-links.subtitle', { 'affiliate_link': getBaseUrl + '/p/' + user.id, 'affiliate_code': user.id })">
            </div>

            Twój link polecającego: <a @click='clipboardHelper.copy(`${getBaseUrl}/p/${user.id}`)' class="clickable-element text-primary">{{ getBaseUrl + "/p/" + user.id }}</a>
            <br>
            Twój kod polecającego: <a @click='clipboardHelper.copy(user.id)' class="clickable-element text-primary">{{ user.id }}</a>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <template v-if="table.items">
              <ecat-table
                  :table="table"
                  :fields="getFields()"
                  :items="loadAccounts"
                  :pagination-top="true"

              />
            </template>
            <template v-else>
              <div class="text-center py-3">
                <h5>{{ $t('message.loading') }}</h5>
                <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>